const dev = {
    apiGateway: {
        REGION: 'us-east-1',
        URL: 'https://pyon1i7uz8.execute-api.us-east-1.amazonaws.com/dev',
    },
    apiGateway2: {
        REGION: 'us-east-1',
        URL: 'https://f75qdmgu2b.execute-api.us-east-1.amazonaws.com/main',
    },
    apiGateway3: {
        REGION: 'us-east-1',
        URL: 'https://uy77uvrde3.execute-api.us-east-1.amazonaws.com/main',
    },
    apiGatewayhttp: {
        REGION: 'us-east-1',
        URL: 'http://localhost:3000',
    },
    cognito: {
        REGION: 'us-east-1',
        USER_POOL_ID: 'us-east-1_U2R4amaia',
        APP_CLIENT_ID: '5347kcmic7bdekv5ubqq47rj88',
        IDENTITY_POOL_ID: 'us-east-1:5c353f11-c689-4881-9758-209113fe2f0d',
    },
    appsync: {
        aws_appsync_graphqlEndpoint:
            'https://wz3yxnbb3zhdlni52pqrrxmngq.appsync-api.us-east-1.amazonaws.com/graphql',
        aws_appsync_region: 'us-east-1',
        aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
        aws_appsync_apiKey: 'null',
    },
    storage: {
        bucket: 'plagia-back-dev-data',
        region: 'us-east-1',
    },
    snapshotBucket:
        'https://plagia-screenshot-dev-snapshotbucket.s3.amazonaws.com/snapshot/',
}

const prod = {
    apiGateway: {
        REGION: 'us-east-1',
        URL: 'https://dryiz4hb5b.execute-api.us-east-1.amazonaws.com/prod',
    },
    apiGateway2: {
        REGION: 'us-east-1',
        URL: 'https://cv222b0o0k.execute-api.us-east-1.amazonaws.com/main',
    },
    apiGateway3: {
        REGION: 'us-east-1',
        URL: 'https://uy77uvrde3.execute-api.us-east-1.amazonaws.com/main',
    },
    apiGatewayhttp: {
        REGION: 'us-east-1',
        URL: 'http://localhost:3000',
    },
    cognito: {
        REGION: 'us-east-1',
        USER_POOL_ID: 'us-east-1_svzYL18U3',
        APP_CLIENT_ID: '30j7o8j7ur5t94s2pvcdtgqmni',
        IDENTITY_POOL_ID: 'us-east-1:de61ada1-0a4c-424b-9abe-168de5aab34d',
    },
    appsync: {
        aws_appsync_graphqlEndpoint:
            'https://n7x7wmtthjci3amjqe3hd7scgq.appsync-api.us-east-1.amazonaws.com/graphql',
        aws_appsync_region: 'us-east-1',
        aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
        aws_appsync_apiKey: 'null',
    },
    storage: {
        bucket: 'plagia-back-prod-data',
        region: 'us-east-1',
    },
    snapshotBucket:
        'https://plagia-screenshot-prod-snapshotbucket.s3.amazonaws.com/snapshot/',
}

const local = {
    apiGateway: {
        REGION: 'us-east-1',
        URL: 'https://637y1j7jog.execute-api.us-east-1.amazonaws.com/local',
    },
    apiGateway2: {
        REGION: 'us-east-1',
        URL: 'https://1i3d1884c2.execute-api.us-east-1.amazonaws.com/main',
    },
    apiGateway3: {
        REGION: 'us-east-1',
        URL: 'https://uy77uvrde3.execute-api.us-east-1.amazonaws.com/main',
    },
    apiGatewayhttp: {
        REGION: 'us-east-1',
        URL: 'https://e3a6i807ji.execute-api.us-east-1.amazonaws.com/v1',
    },
    cognito: {
        REGION: 'us-east-1',
        USER_POOL_ID: 'us-east-1_zJRh7NSyH',
        APP_CLIENT_ID: '4ho3vo9jd2hadkj1o1d33d355u',
        IDENTITY_POOL_ID: 'us-east-1:bfb54b03-f3ca-4460-b140-7bb799e9839e',
    },
    appsync: {
        aws_appsync_graphqlEndpoint:
            'https://mdckjgczgbgspdx65qzaqcikky.appsync-api.us-east-1.amazonaws.com/graphql',
        aws_appsync_region: 'us-east-1',
        aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
        aws_appsync_apiKey: 'null',
    },
    storage: {
        bucket: 'plagia-back-local-data',
        region: 'us-east-1',
    },
    snapshotBucket:
        'https://plagia-screenshot-local-snapshotbucket.s3.amazonaws.com/snapshot/',
}

// Default to dev if not set
const config =
    process.env.GATSBY_STAGE === 'prod'
        ? prod
        : process.env.GATSBY_STAGE === 'dev'
            ? dev
            : local

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config,
}
