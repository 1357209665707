import { Amplify } from 'aws-amplify'
// import Core from '@aws-amplify/core'
// import { Storage } from 'aws-amplify'
import { Auth } from '@aws-amplify/auth'
// import Analytics from '@aws-amplify/analytics'
import config from './config'
import { stripIgnoredCharacters } from 'graphql'

// https://docs.amplify.aws/lib/restapi/authz/q/platform/js/#unauthenticated-requests

export const onClientEntry = () => {
    Amplify.configure({
        // Core.configure({
        Auth: {
            mandatorySignIn: true,
            region: config.cognito.REGION,
            userPoolId: config.cognito.USER_POOL_ID,
            identityPoolId: config.cognito.IDENTITY_POOL_ID,
            userPoolWebClientId: config.cognito.APP_CLIENT_ID,
        },
        Storage: {
            AWSS3: {
                bucket: config.storage.bucket,
                region: config.storage.region,
            },
        },
        API: {
            endpoints: [
                {
                    name: 'domains',
                    endpoint: config.apiGateway.URL,
                },
                {
                    name: 'domains2',
                    endpoint: config.apiGateway2.URL,
                    custom_header: async () => {
                        // return { Authorization: 'token' };
                        // Alternatively, with Cognito User Pools use this:
                        // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
                        return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                    }
                    // region: config.apiGateway2.REGION,
                },
            ],
            aws_appsync_graphqlEndpoint:
                config.appsync.aws_appsync_graphqlEndpoint,
            aws_appsync_region: config.appsync.aws_appsync_region,
            aws_appsync_authenticationType:
                config.appsync.aws_appsync_authenticationType,
            aws_appsync_apiKey: config.appsync.aws_appsync_apiKey,
        }
        // ssr: true
        // Analytics: {
        //     // OPTIONAL - disable Analytics if true
        //     disabled: false,
        //     // OPTIONAL - Allow recording session events. Default is true.
        //     autoSessionRecord: true,

        //     AWSPinpoint: {
        //         appId: config.pinpoint.appId,
        //         // Amazon service region
        //         region: 'us-east-1',
        //     },
        // },
    })
    // Storage.configure({ level: 'private' })
    // Analytics.autoTrack('pageView', {
    //     // REQUIRED, turn on/off the auto tracking
    //     enable: true,
    //     // OPTIONAL, the event name, by default is 'pageView'
    //     eventName: 'pageView',
    //     // OPTIONAL, the attributes of the event, you can either pass an object or a function
    //     // which allows you to define dynamic attributes
    //     attributes: {
    //         attr: 'attr',
    //     },
    //     // when using function
    //     // attributes: () => {
    //     //    const attr = somewhere();
    //     //    return {
    //     //        myAttr: attr
    //     //    }
    //     // },
    //     // OPTIONAL, by default is 'multiPageApp'
    //     // you need to change it to 'SPA' if your app is a single-page app like React
    //     type: 'multiPageApp',
    //     // OPTIONAL, the service provider, by default is the AWS Pinpoint
    //     provider: 'AWSPinpoint',
    //     // OPTIONAL, to get the current page url
    //     getUrl: () => {
    //         // the default function
    //         let path = window.location.pathname
    //         if (path.includes('app/domains/')) {
    //             path = 'app/domains/domainid'
    //         }
    //         return window.location.origin + path
    //     },
    // })
}

export const onRouteUpdate = ({ location, prevLocation }) => {
    // console.log('new pathname', location.pathname)
    // console.log('old pathname', prevLocation ? prevLocation.pathname : null)
    // if (window.civchat) {
    //     UE.pageHit()
    // }
}
